import { graphql, PageProps, useStaticQuery } from 'gatsby';
import React from 'react';
import { ISignMeUpResponse } from '.';
import { IFileImage } from '../@types';
import { LayoutProvider } from '../components/layout';
import {
  LegacyBanner,
  LegacyParallax,
  LegacyStories,
  LegacyStory,
  LegacyVideoLoop,
  LegacyCommunityVideo,
  OurPeople,
  SignMeUp,
} from '../components/sections';
import { IAllBlogs } from './blog';

const query = graphql`
  query OurLegacy {
    allStrapiBlogCategories {
      nodes {
        id
        Name
      }
    }
    strapiWeAreCannon {
      storyMedia {
        id
        title
        subtitle
        bodyText
        videoLink
        mediaPreview {
          url
          ext
          hash
        }
        media {
          url
          ext
          hash
        }
      }
      Stories {
        title
        subtitle
        id
        enable
        actionButton
        actionHref
      }
      videoLoop {
        url
      }
      parallaxBanner {
        url
      }
      banner {
        title
        subTitle
        media {
          url
          ext
        }
      }
      mediaBanner {
        title
        subtitle
        enable
        mediaLink
        media {
          url
          ext
        }
      }
      ourPeople {
        subtitle
        textBody
        title
        media {
          url
          ext
        }
      }
      blogEntries {
        id
        title
        slug
        previewText
        mainArticle
        blogCategory
        mainMedia {
          alternativeText
          ext
          hash
          url
          formats {
            thumbnail {
              ext
              hash
              url
            }
          }
        }
        previewImage {
          url
        }
      }
    }
    strapiSignMeUp {
      acknowledgment
      action
      description
      enabled
      placeholder
      text
      background {
        id
        alternativeText
        caption
        created_at
        ext
        hash
        height
        mime
        name
        provider
        size
        updated_at
        url
        width
        formats {
          thumbnail {
            ext
            hash
            height
            mime
            name
            width
            url
            size
          }
        }
      }
    }
  }
`;

export interface IStoriesLegacy {
  id: number;
  title: string;
  subtitle: string;
  bodyText: string;
  videoLink: string;
  mediaPreview: {
    url: string;
    ext: string;
    hash: string;
  };
  media: {
    url: string;
    ext: string;
    hash: string;
  };
}

export interface IBlogEntries {
  id: number;
  title: string;
  slug: string;
  previewText: string;
  mainArticle: boolean;
  blogCategory: number;
  mainMedia: IFileImage;
  previewImage: IFileImage;
}

export interface IDataOurLegacy {
  strapiWeAreCannon: {
    parallaxBanner: {
      url: string;
    };
    videoLoop: {
      url: string;
    };
    banner: {
      title: string;
      subTitle: string;
      media: {
        url: string;
        ext: string;
      };
    };
    mediaBanner: {
      title: string;
      subtitle: string;
      enable: boolean;
      mediaLink: string;
      mediaPreview: {
        url: string;
        ext: string;
      };
      media: {
        url: string;
        ext: string;
      };
    };
    ourPeople: {
      subtitle: string;
      textBody: string;
      title: string;
      media: {
        url: string;
        ext: string;
      };
    };
    Stories: {
      title: string;
      subtitle: string;
      id: number;
      enable: boolean;
      actionButton: string;
      actionHref: string;
    };
    blogEntries: Array<IBlogEntries>;
    storyMedia: Array<IStoriesLegacy>;
  };
}

interface IData extends IAllBlogs, IDataOurLegacy, ISignMeUpResponse {}

const OurLegacy = (props: PageProps) => {
  const data = useStaticQuery<IData>(query);

  return (
    <LayoutProvider seo locationSearch={props.location.search}>
      <LegacyBanner
        title={data.strapiWeAreCannon.banner.title}
        subtitle={data.strapiWeAreCannon.banner.subTitle}
        url={data.strapiWeAreCannon.banner.media.url}
      />
      <LegacyStory data={data.strapiWeAreCannon.storyMedia} />
      <LegacyCommunityVideo
        mediaTitle={data.strapiWeAreCannon.mediaBanner.title}
        mediaSubtitle={data.strapiWeAreCannon.mediaBanner.subtitle}
        mediaLink={data.strapiWeAreCannon.mediaBanner.mediaLink}
        mediaURL={data.strapiWeAreCannon.mediaBanner.media.url}
        mediaOn={data.strapiWeAreCannon.mediaBanner.enable}
        mediaExt={data.strapiWeAreCannon.mediaBanner.media.ext}
      />
      <LegacyParallax background={data.strapiWeAreCannon.parallaxBanner.url} />
      {data.strapiWeAreCannon.Stories.enable && (
        <LegacyStories
          title={data.strapiWeAreCannon.Stories.title}
          subtitle={data.strapiWeAreCannon.Stories.subtitle}
          blogEntries={data.strapiWeAreCannon.blogEntries}
          actionButton={data.strapiWeAreCannon.Stories.actionButton}
          actionHref={data.strapiWeAreCannon.Stories.actionHref}
          blogCategory={data.allStrapiBlogCategories.nodes}
        />
      )}
      <LegacyVideoLoop src={data.strapiWeAreCannon.videoLoop.url} />
      <OurPeople
        title={data.strapiWeAreCannon.ourPeople.title}
        subtitle={data.strapiWeAreCannon.ourPeople.subtitle}
        textBody={data.strapiWeAreCannon.ourPeople.textBody}
        url={data.strapiWeAreCannon.ourPeople.media.url}
        ext={data.strapiWeAreCannon.ourPeople.media.ext}
      />
      <SignMeUp strapiSignMeUp={data.strapiSignMeUp} />
    </LayoutProvider>
  );
};

export default OurLegacy;
